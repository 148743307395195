// const useProductPriceVariants = async () => {
//   const productPrices = useState<DataSourceEntry[]>("productPrices", () => []);

//   if (productPrices.value.length > 0) return productPrices.value;

//   const storyBlokApi = useStoryblokApi();
//   const { data } = await storyBlokApi.get("cdn/datasource_entries", {
//     datasource: "product-price",
//   });

//   productPrices.value = data?.datasource_entries || [];

//   return productPrices.value;
// };

export const useProductPriceName = (price: string): string => {
  // const prices = await useProductPriceVariants();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, name] = price.split("-");

  return name || price;
};
